.oInput {
  position: relative;
  margin: 10px 0;
}

.oInputField {
  display: block;
  width: 100%;
  padding: 5px 10px;
  appearance: none;
  -webkit-appearance: none;
  border: none;
  border-bottom: 3px dotted var(--color-black);
  margin-bottom: 5px;
  border-radius: 0;

  &:focus {
    outline: none;
  }
}

.oInputField:disabled {
  border-color: #d2d2d2;
  pointer-events: none;
}

.oInputField:disabled + .oInputLabel {
  color: #d2d2d2;
}

.oInputLabel {
  cursor: text;
  user-select: none;
  transition: all .4s;
  padding: 5px;
}
