.oUpload {
  position: relative;
  margin: 10px 0;
}

.oUploadField {
  display: block;
  width: 100%;
  padding: 5px 0;
  margin-bottom: 5px;

  &:focus {
    outline: none;
  }
}

.oUploadField:disabled {
  border-color: #d2d2d2;
  pointer-events: none;
}

.oUploadField:disabled + .oUploadLabel {
  color: #d2d2d2;
}

.oUploadLabel {
  cursor: text;
  user-select: none;
  transition: all .4s;
  padding: 5px;
}
