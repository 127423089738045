@import "../../../../sass/base/_breakpoints.scss";

.oCheckbox {
  position: relative;
  display: block;
  min-height: 25px;
  line-height: 25px;
  margin: 5px 0;
}

.oCheckboxField {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  pointer-events: none;
}

.oCheckboxLabel {
  display: block;
  padding: 5px 5px 5px 40px;
  cursor: pointer;
  background: transparent !important;
  color: var(--color-black) !important;
}

.oCheckboxLabel:before,
.oCheckboxLabel:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
}

.oCheckboxLabel:before {
  width:  30px;
  height: 30px;
  background: transparent;
  border: 2px solid var(--color-black);
  transform: translateY(-50%);
}

.oCheckboxLabel:after {
  width: 7px;
  height: 13px;
  border-right: 2px solid var(--color-black);
  border-bottom: 2px solid var(--color-black);
  left: 4px;
  transition: transform .2s;
  transform: rotate(45deg) translateY(calc(-50% - 4px)) scale(0);
}

.oCheckboxField:checked + .oCheckboxLabel:after {
  transform: rotate(45deg) translateY(calc(-50% - 4px)) scale(1);
}

.terms {
  .oCheckboxLabel {
    padding: 5px 40px;
  }

  .oCheckboxLabel:before,
  .oCheckboxLabel:after {
    top: 25px;
  }

  @include media-breakpoint('m') {
    font-size: 14px;
    line-height: 16px;
  }
}