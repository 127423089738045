:root {
  /*--------------------------------------------------------------
  # TYPOGRAPHY
  --------------------------------------------------------------*/

  --site-font-family: 'Archivo', sans-serif;
  --site-font-size: 1;
  --site-line-height: 1.35;
  --site-font-weight: 400;
  --site-letter-spacing: 0;
  --paragraph-offset: 1.5em;
  --secondary-font-family: serif;

  /*--------------------------------------------------------------
  # COLORS
  --------------------------------------------------------------*/

  --color-white: #ffffff;
  --color-black: #000000;
  --color-red: #e6224e;
  --color-green: #5db24c;
  --color-darkgreen: #31991c;
  --color-gray: #557984;
  --color-orange: #f29224;
  --color-darkorange: #e84235;
  --color-blue: #2492d1;
  --color-darkblue: #18759e;
  --color-lightgray: #F5F5F5FF;

  --color-rainbow: linear-gradient(to right, #5db24c 0%, #557984 22%, #e6224e 41%, #f29224 60%, #2492d1 100%);

  --site-color-bg: #ffffff;
  --site-color-text: #000000;
}
