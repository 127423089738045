@import "../../sass/base/_breakpoints.scss";
@import "../../sass/base/_border.scss";

.infos {
  border: 6px solid var(--color-red);
  padding: 10px;
}

.imageHolder {
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 25%;

  @include media-breakpoint('m') {
    padding-bottom: 50%;
  }
}

.image {
  position: absolute;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.infoTitleHolder {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: -10px 0 40px;
}

.infoTitle {
  color: var(--color-red);
  text-transform: uppercase;
  font-family: Archivo, sans-serif;
  font-weight: 900;
  font-size: 20px;
  margin-right: 15px;
}

.infoIcon {
  width: 60px;
  background: var(--color-white);
  border-radius: 50%;
  margin-top: -20px;
}

.infoSubTitle {
  color: var(--color-red);
  text-transform: uppercase;
  font-family: Archivo, sans-serif;
}

.title {
  color: var(--color-red);
  text-transform: uppercase;
  font-family: Archivo, sans-serif;
  font-weight: 900;
  font-size: 50px;
  margin-bottom: 20px;
  word-break: break-word;
  width: 100%;
  hyphens: auto;

  @include media-breakpoint('m') {
    font-size: 32px;
  }
}

.preTitle {
  color: var(--color-red);
  text-transform: uppercase;
  font-family: Archivo, sans-serif;
  font-size: 28px;
  margin-bottom: 10px;

  @include media-breakpoint('m') {
    font-size: 22px;
  }
}

.infoValue {
  word-break: break-word;
  hyphens: auto;

  a {
    hyphens: inherit;
  }
}

.infoContent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
}

.icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  flex-basis: 40px;
}

.infoHeading {
  flex: 1;
}

.meta {
  display: flex;
  color: var(--color-red);
  margin-bottom: 50px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px;

  @include media-breakpoint('m') {
    margin-bottom: 20px;
  }
}

.author {
  margin-right: 5px;
}

.date {
  margin-left: 5px;
}

.blogLink {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}