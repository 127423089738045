h1,
h2,
h3,
h4,
h5,
h6,
.like-h1,
.like-h2,
.like-h3,
.like-h4,
.like-h5,
.like-h6 {
  font-family: 'Archivo', sans-serif;
  margin-top: 40px;
  margin-bottom: .75em;
  font-weight: 400;
}

h1,
.like-h1 {
  font-family: 'Archivo', sans-serif;
  font-size: 3rem;
  font-weight: 900;
  line-height: 50px;
  text-transform: uppercase;
}

h2,
.like-h2 {
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 34px;
  font-weight: 900;
}

h3,
.like-h3 {
  font-size: 1.75rem;
  line-height: 30px;
  font-weight: 900;
  text-transform: uppercase;
}

h4,
.like-h4 {
  font-size: 1.3125rem;
  font-weight: 900;
  line-height: 26px;
}

h5,
.like-h5 {
  font-size: 1.125rem;
  line-height: 26px;
}

h6,
.like-h6 {
  font-size: 1rem;
  line-height: 24px;
}

@media only screen and ( max-width: 900px ) {
  h1,
  .like-h1 {
    font-size: 1.625rem;
    line-height: 29px;
  }
}
