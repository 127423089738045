$thin:   4px;
$normal: 7px;
$thick:  10px;


// COLORS

.borderRed {
  border-color: var(--color-red);
}





// STYLES

.borderSolid {
  border-style: solid;
}

.borderDashed {
  border-style: dashed;
}





// THICKNESS/WIDTH

.borderThin {
  border-width: $thin;
}

.borderNormal {
  border-width: $normal;
}

.borderThick {
  border-width: $thick;
}






// IMAGES
.borderThin {
  .dashedImage {
    left:   -$thin;
    right:  -$thin;
    top:    -$thin;
    bottom: -$thin;
    width: calc(100% + 8px);
    max-width: calc(100% + 8px);
    height: calc(100% + 8px);
    z-index: -1;
  }
}

.borderNormal {
  .dashedImage {
    left:   -$normal;
    right:  -$normal;
    top:    -$normal;
    bottom: -$normal;
    width: calc(100% + 14px);
    max-width: calc(100% + 14px);
    height: calc(100% + 14px);
    z-index: -1;
  }
}

.borderThick {
  .dashedImage {
    left:   -$thick;
    right:  -$thick;
    top:    -$thick;
    bottom: -$thick;
    width: calc(100% + 20px);
    max-width: calc(100% + 20px);
    height: calc(100% + 20px);
    z-index: -1;
  }
}

