@import "../../sass/base/_breakpoints.scss";

.header {
  padding: 80px 0 40px;

  @include media-breakpoint('s') {
    padding: 60px 0 40px;
  }
}

.headerTop {
  position: relative;
  min-height: 132px;

  @include media-breakpoint('s') {
    min-height: 110px;
  }
}

.background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;

  @include media-breakpoint(1100px) {
    width: calc(100% + 200px);
    left: -100px;
  }
}

.headerTopInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoContainerLeft {
  display: flex;
  align-items: flex-end;
  position: absolute;
  left: 12%;
  bottom: 30px;

  @include media-breakpoint('m') {
    left: 5%;
  }
}

.logoContainerRight {
  position: absolute;
  right: 0;
  bottom: 100%;
}

.textLogo {
  display: block;
  max-width: 250px;
  width: 100%;
  height: auto;

  @include media-breakpoint('s') {
    max-width: 160px;
  }
  @include media-breakpoint('xs') {
    max-width: 130px;
  }
}

.astronaut {
  display: block;
  max-width: 300px;
  margin-left: -25px;

  @include media-breakpoint('s') {
    max-width: 250px;
  }
  @include media-breakpoint('xs') {
    max-width: 200px;
  }
  @include media-breakpoint('xxs') {
    margin-bottom: -25px;
  }
}

.tuLogo {
  height: 50px;

  @include media-breakpoint('m') {
    max-height: 35px;
  }
}

.title {
  font-weight: 900;
  font-size: 22px;

  @include media-breakpoint('xs') {
    font-size: 16px;
    display: flex;
    gap: 10px;
    line-height: 1;
  }
}

.burger {
  display: none;
  width: 45px;

  span {
    height: 4px;
    width: 100%;
    background: var(--color-red);
    transition: background .3s;
  }

  &.is-open {
    span {
      background: gray;
    }
  }

  @include media-breakpoint('xs') {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
}

.menuHolder {
  @include media-breakpoint('xs') {
    max-height: 0;
    transition: max-height .5s;
    overflow: hidden;
    height: 100%;

    &.is-opening {
      max-height: 100vh;
      pointer-events: none;
    }

    &.is-open {
      max-height: none;
    }
  }
}

.menu {
  border-bottom: 4px dotted black;
  padding-bottom: 10px;
  margin: 30px 0 10px;
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  font-weight: 900;

  @include media-breakpoint(1000px) {
    font-size: 18px;
  }

  @include media-breakpoint(820px) {
    display: block;
  }
}

.menuItem {
  text-decoration: none;
  text-transform: uppercase;
  transition: color .15s;

  &:hover {
    color: var(--color-red);
  }

  @include media-breakpoint(820px) {
    display: block;
    text-align: center;
  }
}