.wysiwyg a {
  color: var(--color-black);
  font-size: 15px;
  line-height: 19px;
  letter-spacing: 0.1em;
  text-decoration: none;
  background-image: linear-gradient( to left, var(--color-black), var(--color-black) );
  background-position: 0 93%;
  background-repeat: no-repeat;
  background-size: 0 1px;
  transition: all .3s;
}

.wysiwyg a:hover {
  background-size: 100% 1px;
}

.wysiwyg > *:first-child {
  margin-top: 0;
}

.wysiwyg > *:last-child {
  margin-bottom: 0;
}
