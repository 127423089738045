@import "../../sass/base/_breakpoints.scss";

.infos {
  position: sticky;
  top: 15px;
}

.infosInner {
  position: relative;
  box-sizing: border-box;
  background: var(--site-color-bg);
  background-clip: padding-box;
  border: solid 6px transparent;
  border-radius: 27px;
  padding: 30px 20px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -6px;
    border-radius: inherit;
    background: var(--color-rainbow);
  }
}

.imageHolder {
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 50%;

  @include media-breakpoint('m') {
    margin-top: 30px;
  }
}

.image {
  position: absolute;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.infoTitleHolder {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 0 40px;
}

.infoTitle {
  flex: 1;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 20px;
  margin-right: 15px;
  line-height: 1.125;
  hyphens: auto;
  width: 100%;
}

.infoIcon {
  width: 40px;
  min-width: 40px;
  height: 40px;
  margin-right: 10px;
  flex-basis: 40px;
}

.infoSubTitle {
  font-size: 20px;
  font-weight: 900;
  line-height: 1.125;
  text-transform: uppercase;
  font-family: Archivo, sans-serif;
  margin: 8px 0 10px;
}

.title {
  text-transform: uppercase;
  font-family: Archivo, sans-serif;
  font-weight: 900;
  font-size: 50px;
  margin-bottom: 20px;
  hyphens: auto;
  line-height: 1;

  @include media-breakpoint('m') {
    font-size: 28px;
    margin-top: 30px;
  }
}

.content {
  > div > div,
  > div {
    padding-left: 0;
    padding-right: 0;
  }

  form {
    margin: 0 -15px;
    width: calc(100% + 30px);
  }
}

.infoValue {
  word-break: break-word;
  hyphens: auto;
  margin-bottom: 15px;

  a {
    hyphens: inherit;
  }

  p {
    margin: 0 !important;
  }
}

.infoContent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
}

.icon {
  width: 40px;
  min-width: 40px;
  height: 40px;
  margin-right: 10px;
  flex-basis: 40px;
}

.infoHeading {
  flex: 1;
}
