@import "../../sass/base/breakpoints";

.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
}

.container.full {
  max-width: none;
  padding: 0;
}

.container.small {
  max-width: 800px;
}

.container.xsmall {
  max-width: 600px;
}

.container.middle {
  max-width: 1000px;
}

.container.large {
  max-width: 1400px;
}

.container.dotted {
  border: 3px dotted var(--color-black);
  padding: 30px 50px;
  margin-top:    30px;
  margin-bottom: 30px;

  @include media-breakpoint('s') {
    padding: 20px 30px;
  }
}

.container.rainbow {
  position: relative;
  box-sizing: border-box;
  background: var(--site-color-bg);
  background-clip: padding-box;
  border: solid 6px transparent;
  border-radius: 27px;
  padding: 30px;
  margin-top:    30px;
  margin-bottom: 30px;

  @include media-breakpoint('s') {
    padding: 20px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -6px;
    border-radius: inherit;
    background: var(--color-rainbow);
  }
}

.container.dotted,
.container.rainbow {
  &:not(.xsmall, .small, .middle, .large, .full) {
    max-width: 1070px;
    @include media-breakpoint(1115px) {
      width: calc(100% - 30px);
      margin: 0 15px;
    }
  }

  &.full {
    width: calc(100% - 30px);
    margin: 0 15px;
  }

  &.xsmall {
    max-width: 570px;
    @include media-breakpoint(600px) {
      width: calc(100% - 30px);
      margin: 0 15px;
    }
  }
  &.small {
    max-width: 770px;
    @include media-breakpoint(800px) {
      width: calc(100% - 30px);
      margin: 0 15px;
    }
  }
  &.middle {
    max-width: 970px;
    @include media-breakpoint(1000px) {
      width: calc(100% - 30px);
      margin: 0 15px;
    }
  }
  &.large {
    max-width: 1370px;
    @include media-breakpoint(1400px) {
      width: calc(100% - 30px);
      margin: 0 15px;
    }
  }
}


$current: 5;

@while $current <= 150 {
  .u-margin-top-#{$current} {
    margin-top: $current + px;
  }

  .u-margin-bottom-#{$current} {
    margin-bottom: $current + px;
  }

  .padding-top-#{$current} {
    padding-top: $current + px;
  }

  .padding-bottom-#{$current} {
    padding-bottom: $current + px;
  }

  .padding-top-m-#{$current} {
    @include media-breakpoint('m') {
      padding-top: $current + px !important;
    }
  }

  .padding-bottom-m-#{$current} {
    @include media-breakpoint('m') {
      padding-bottom: $current + px !important;
    }
  }

  $current: $current + 5;
}