.oRadioButtons {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 10px;

  &.isVertical {
    flex-direction: column;
  }
}

.oRadioName {
  cursor: text;
  user-select: none;
  transition: all .4s;
  padding: 5px;
}

.oRadio {
  position: relative;
  margin-bottom: 20px;
}

.oRadioLabel {
  margin: 0 20px 0 25px;
  cursor: pointer;
  background: var(--color-white) !important;
  color: var(--color-black) !important;
}


.oRadioField:before,
.oRadioField:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
}

.oRadioField:before {
  width:  30px;
  height: 30px;
  background: var(--color-white);
  border: 2px solid var(--color-black);
  transform: translateY(-50%);
}

.oRadioField:after {
  width: 7px;
  height: 13px;
  border-right: 2px solid var(--color-black);
  border-bottom: 2px solid var(--color-black);
  left: 4px;
  transition: transform .2s;
  transform: rotate(45deg) translateY(calc(-50% - 4px)) scale(0);
}

.oRadioField:checked {
  &:after {
    transform: rotate(45deg) translateY(calc(-50% - 4px)) scale(1);
  }
}
