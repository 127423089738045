.title {
  font-family: Archivo, sans-serif;
  text-align: center;
  font-size: 32px;
  text-transform: uppercase;
  margin-bottom: 50px;
  transition: opacity .5s;
  opacity: 1;
  position: relative;
  display: block;
  text-decoration: none;
  font-weight: 900;
  color: var(--color-red);
}

.titleIcon {
  width: 95px;
  position: absolute;
  top: -15px;
  left: calc(50% - 170px);
}

$site-cols: 4;
$gap: 10px;

.posts {
  display: grid;
  grid-auto-flow: dense;
  grid-gap: $gap;
  row-gap:  $gap;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(4, 1fr);
  margin: 20px 0 50px;
}


.loadMoreHolder {
  display: flex;
  justify-content: flex-end;
}

.loadMore {
  font-family: Archivo, sans-serif;
  font-size: 18px;
  display: flex;
  align-items: center;
  transition: color .3s;
  text-decoration: none;
  font-weight: 900;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
    color: var(--color-red);

    .loadMoreIcon {
      fill: var(--color-red);
    }
  }
}

.loadMoreIcon {
  width: 35px;
  display: block;
  margin-right: 10px;
  transition: fill .3s;
}