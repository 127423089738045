@import "../../sass/base/_breakpoints.scss";

.footer {
  padding: 50px 0;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: 0.1em;
}

.footerRight {
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .footerLeft {
    text-align: center;
  }

  .footerRight {
    text-align: center;
    order: -1;
  }
}
