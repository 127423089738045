.oTextarea {
  position: relative;
  margin: 10px 0;
}

.oTextareaField {
  display: block;
  width: 100%;
  padding: 35px 10px 5px;
  appearance: none;
  border: 2px solid var(--color-black);
  margin-bottom: 5px;
  min-height: 150px;

  &:focus {
    outline: none;
  }
}

.oTextareaField:disabled {
  border-color: #d2d2d2;
  pointer-events: none;
}

.oTextareaField:disabled + .oTextareaLabel {
  color: #d2d2d2;
}

.oTextareaLabel {
  cursor: text;
  user-select: none;
  transition: all .4s;
  position: absolute;
  top: 10px;
  left: 10px;
}
