/**
 Typical font-weight names/aliases

 100 => Thin, Hairline
 200 => Extra Light, Ultra Light
 300 => Light
 400 => Normal, Regular
 500 => Medium
 600 => Semi Bold, Demi Bold
 700 => Bold
 800 => Extra Bold, Ultra Bold
 900 => Black, Heavy
 */


/* archivo */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/Archivo-Regular.ttf');  /* IE9 Compat Modes */
}
@font-face {
  font-family: 'Archivo';
  font-style: italic;
  font-weight: 400;
  src: url('../../assets/fonts/Archivo-Italic.ttf');  /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 500;
  src: url('../../assets/fonts/Archivo-Medium.ttf');  /* IE9 Compat Modes */
}
@font-face {
  font-family: 'Archivo';
  font-style: italic;
  font-weight: 500;
  src: url('../../assets/fonts/Archivo-MediumItalic.ttf');  /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/Archivo-Bold.ttf');  /* IE9 Compat Modes */
}
@font-face {
  font-family: 'Archivo';
  font-style: italic;
  font-weight: 700;
  src: url('../../assets/fonts/Archivo-BoldItalic.ttf');  /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 900;
  src: url('../../assets/fonts/Archivo-Black.ttf');  /* IE9 Compat Modes */
}
@font-face {
  font-family: 'Archivo';
  font-style: italic;
  font-weight: 900;
  src: url('../../assets/fonts/Archivo-BlackItalic.ttf');  /* IE9 Compat Modes */
}
