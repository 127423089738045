@import "../../sass/base/_breakpoints.scss";

.image {
  width: 100%;
}

.video {
  width: 100%;
}

.slider {
  width: 100%;
  height: 100%;

  > div {
    height: 100%;
  }
}

.textHolder {
  white-space: pre-wrap;

  @include media-breakpoint('m') {
    margin: 30px 0;
  }
}
