@import "../../sass/base/_breakpoints.scss";


.filter {
  max-height: 0;
  transition: max-height .3s, padding-top .3s;
  overflow: hidden;

  &.is-open {
    padding-top: 20px;
    max-height: 500px;

    @include media-breakpoint('m') {
      max-height: 750px;
    }
  }
}

.inner {
  display: flex;
  margin-right: -15px;

  @include media-breakpoint('m') {
    margin-left: -2px;
    margin-right: -2px;

    > div {
      padding: 0 2px;
      width: 100%;
    }
  }

  @include media-breakpoint('xs')  {
    margin-left: 0;
    margin-right: 0;

    > div {
      padding: 0;
      width: 100%;
    }
  }
}

.subFilter {
  border: 0 solid var(--color-gray);
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  gap: 15px;
  opacity: 0;
  position: relative;
  overflow: hidden;
  max-height: 0;
  transition: all .45s;
  justify-content: flex-end;

  &.center {
    justify-content: center;

    .filterEntryBody {
      flex-basis: calc(33.33% - 11.5px);
      width: calc(33.33% - 11.5px);
    }
  }

  &.isOpen {
    border-width: 4px;
    opacity: 1;
    max-height: 1000px;
    padding: 10px;

    &.center {
      padding: 10px 100px !important;

      @include media-breakpoint('m') {
        padding: 10px 100px 10px 10px !important;
      }
      @include media-breakpoint('s') {
        padding: 10px 50px 10px 10px !important;
      }
      @include media-breakpoint('xs') {
        padding: 5px !important;
      }
    }

    &.search {
      padding: 0 !important;
      height: 70px;
    }

    @include media-breakpoint('m') {
      padding-right: 100px !important;
      gap: 10px;
    }
    @include media-breakpoint('s') {
      padding-right: 50px !important;
      gap: 8px;
    }
    @include media-breakpoint('xs') {
      padding: 5px !important;
    }
  }
}

.subFilter.threeColumns {
  width: calc(75% - 2.5px);

  @include media-breakpoint( 'm' ) {
    width: 100%;
  }
}

.filter.hasOpenFilter {
  .subFilter {
    transition: opacity .45s !important;
  }
}


.filter.searchOpen {
  .inner {
    > div:first-child,
    > div:nth-child(2),
    > div:nth-child(3) {
      opacity: .3;
    }
  }

  .actionsButtons > a:first-child,
  .activeFilters {
    pointer-events: none;
    color: rgba(0, 0, 0, 0.3);

    &:before {
      opacity: .3;
    }
  }
}

.slider.closed {}

.filterEntryInner {
  border: 4px solid var(--color-gray);
  color: var(--color-gray);
  line-height: 1;
  height: 100%;
  font-family: 'Archivo', sans-serif;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 800;
  letter-spacing: 0.03em;
  cursor: pointer;
  position: relative;
  border-radius: 15px 15px 0 0;

  &:active {
    transform: translateY(1px);
  }

  @include media-breakpoint('m')  {
    font-size: 18px;
    padding: 15px !important;
  }

  @include media-breakpoint('xs')  {
    font-size: 14px;
  }
}

.inner {
  > div:first-child {
    .filterEntryInner {
      border-color: var(--color-blue);
      color: var(--color-blue);

      &:before,
      &:before {
        background: var(--color-blue);
      }
    }

    .filterEntry.hasValues:after {
      background: var(--color-darkblue);
    }
  }

  > div:nth-child(2) {
    .filterEntryInner {
      border-color: var(--color-darkorange);
      color: var(--color-darkorange);

      &:before,
      &:before {
        background: var(--color-darkorange);
      }
    }

    .filterEntry.hasValues:after {
      background: var(--color-red);
    }
  }

  > div:nth-child(3) {
    .filterEntryInner {
      border-color: var(--color-green);
      color: var(--color-green);

      &:before,
      &:before {
        background: var(--color-green);
      }
    }

    .filterEntry.hasValues:after {
      background: var(--color-darkgreen);
    }
  }
}

.filterEntryBodyInner {
  > div:first-child {
    border-color: var(--color-blue);

    .filterEntryBody:before,
    .filterEntryBody.active {
      background: var(--color-darkblue);
      border-color: var(--color-darkblue);
    }
    .filterEntryBody:hover {
      border-color: var(--color-darkblue);
    }

    .filterEntryBody {
      border-color: var(--color-blue);
    }
  }

  > div:nth-child(2) {
    border-color: var(--color-darkorange);

    .filterEntryBody:before,
    .filterEntryBody.active {
      background: var(--color-red);
      border-color: var(--color-red);
    }
    .filterEntryBody:hover {
      border-color: var(--color-red);
    }

    .filterEntryBody {
      border-color: var(--color-darkorange);
    }
  }

  > div:nth-child(3) {
    border-color: var(--color-green);

    .filterEntryBody:before,
    .filterEntryBody.active {
      border-color: var(--color-darkgreen);
      background: var(--color-darkgreen);
    }
    .filterEntryBody:hover {
      border-color: var(--color-darkgreen);
    }

    .filterEntryBody {
      border-color: var(--color-green);
    }
  }
}


.filterEntryBody,
.filterEntryInner {
  cursor: pointer;
  position: relative;
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color .5s;
  overflow: hidden;
}

.filterEntryBody:before,
.filterEntryInner:before {
  background: var(--color-gray);
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 250%;
  height: 100%;
  transform-origin:0 0 ;
  transform: translateX(-120%) skewX(45deg);
  transition: transform .5s;
  z-index: -1;

  @include media-breakpoint('m') {
    width: 500px;
    height: 100px;
  }
}

.filterEntryBody.active,
.filterEntry.active .filterEntryInner,
.filterEntryBody:hover,
.filterEntry:hover .filterEntryInner {
  color: var(--color-white) !important;

  &:before {
    transform: translateX(-20%) skewX(45deg);
  }
}

.filterEntryBodyDesktop {
  display: block;
  @include media-breakpoint('m') {
    display: none;
  }
}

.filterEntryBodyMobile {
  display: none;
  margin-bottom: 50px;

  @include media-breakpoint('m') {
    display: block;
  }
}

.filterEntry.hasValues:after {
  background: var(--color-gray);
  content: attr(data-active-count);
  position: absolute;
  top:   5px;
  right: 5px;
  border: 1px solid var(--color-white);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  font-size: 20px;
  color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;

  @include media-breakpoint('m') {
    width: 26px;
    height: 26px;
    font-size: 16px;
  }
  @include media-breakpoint('xs') {
    top: -10px;
    transform: translateX(50%);
    right: 50%;
  }
}

.filterEntryBody {
  color: var(--color-black);
  border: 3px solid var(--color-gray);
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
  text-align: center;
  padding: 12px 5px;
  cursor: pointer;
  flex-basis: calc(25% - 11.5px);
  width: calc(25% - 11.5px);
  line-height: 1;

  @include media-breakpoint('m') {
    max-width: calc(50% - 5px) !important;
    min-width: calc(50% - 5px) !important;
    margin: 0 !important;
    hyphens: auto;

    &:nth-child(7),
    &:nth-child(even) {
      max-width: calc(50%);
      min-width: calc(50%);
      margin-right: 0;
    }
  }

  @include media-breakpoint('xs') {
    padding: 8px 4px;
  }

  &:active {
    transform: translateY(1px);
  }
}

.threeColumns .filterEntryBody {
  flex-basis: calc(33.33% - 10.33px);
  width: calc(33.33% - 10.33px);
}

.filterEntryFor {
  width:      40px;
  flex-basis: 40px;
  height:     40px;

  @include media-breakpoint('m') {
    flex-basis: calc(25% - 11.5px);
    width: calc(25% - 11.5px);
    min-width: 0 !important;
  }

  @include media-breakpoint('s') {
    flex-basis: calc(25% - 6.5px);
    width: calc(25% - 6.5px);
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 100%;
    background: var(--color-red);
    transform-origin:0 0 ;
    transform: translateX(-140%) skewX(45deg);
    transition: transform .5s;
    z-index: -1;
  }
}

.sepeartor {
  min-width: 20px;
  display: flex;
  justify-content: center;

  @include media-breakpoint('m') {
    display: none;
  }
}

.sepeartorInner {
  width: 3px;
  height: 95%;
  background: var(--color-blue);
}

.filterEntryBody.active {
  background: var(--color-gray);
  color: var(--color-white);
}

.forTitle {
  line-height: 1;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
  color: var(--color-blue);
  text-align: center;
  padding: 10px;
  flex-basis: calc(12%);
  width: calc(12%);
  font-size: 20px;

  @include media-breakpoint('m') {
    flex-basis: calc(33.33%);
    width: calc(33.33%);
  }
  @include media-breakpoint('xs') {
    flex-basis: calc(50%);
    width: calc(50%);
  }
}

.socialContainer {
  flex-grow: 1;

  @include media-breakpoint('m') {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}


.search {
  font-weight: 800;
  font-family: 'Archivo', sans-serif;
  font-size: 20px;
  letter-spacing: 0.03em;
  color: var(--color-gray);
  text-align: right;
  width: 100%;
  display: block;
  text-decoration: none;
  text-transform: uppercase;

  @include media-breakpoint('m') {
    width: auto;
  }
}

.news {
  padding-right: 4px;

  &:hover {
    text-decoration: underline;
  }
}

.reset {
  font-family: 'Archivo', sans-serif;
  font-weight: 800;
  color: var(--color-red);
  font-size: 16px;
  letter-spacing: 0.03em;
  cursor: pointer;
  text-decoration: none;

  @include media-breakpoint('m') {
    font-size: 14px;
  }

  &:hover {
    text-decoration: underline;
  }
}

.submit {
  font-family: 'Archivo', sans-serif;
  font-weight: 800;
  font-size: 16px;
  letter-spacing: 0.03em;
  cursor: pointer;
  text-decoration: none;
  padding: 10px;
  display: flex;

  @include media-breakpoint('m') {
    font-size: 14px;
  }

  svg {
    width: 12px;
    margin-left: 10px;
  }

  &:hover {
    text-decoration: underline;
  }
}

.sm {
  text-align: right;
  margin-top: 10px;
  padding-right: 4px;
}

.smIcon {
  width: 27px;
  height: auto;
  margin-left: 10px;
  transition: fill .3s;

  &:hover {
    fill: var(--color-red);
  }
}

.filterEntry {
  position: relative;
  height: calc(100% - 10px);
  transition: height .15s;

  @include media-breakpoint('m') {
    height: calc(100% - 5px);
  }

  @include media-breakpoint('xs') {
    height: 100%;
  }

  &.active {
    height: 100%;
  }
}

.filterEntry.search {
  .filterEntryInner {
    font-size: 22px;
    text-align: left;

    @include media-breakpoint('xl')  {
      font-size: 19px;
    }

    @include media-breakpoint('l')  {
      font-size: 16px;
    }

    @include media-breakpoint('m')  {
      span {
        display: none;
      }
    }

    @include media-breakpoint('s')  {
      font-size: 13px;
    }
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  flex-wrap: wrap;
  align-items: center;
}

.activeFilters {
  opacity: 1;
}

.actionsButtons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.searchForm {
  position: relative;
  height: 100%;
}

.searchInput {
  width: 100%;
  height: 100%;
  text-align: center;
  appearance: none;
  border-radius: 0;
  border: 0;
}

.searchIcon {
  min-width: 26px;
  width:     26px;
  height:    26px;
  margin-right: 7px;
  fill: var(--color-gray);
  transition: fill .3s;

  @include media-breakpoint('l')  {
    min-width: 25px;
    width:  25px;
    height: 25px;
  }
}

.filterEntryBody.active,
.filterEntry.active .filterEntryInner,
.filterEntryInner:hover {
  .searchIcon {
    fill: var(--color-white);
  }
}

.filterButtonHolder {
  @include media-breakpoint(820px) {
    text-align: center;
  }
}

.filterButton {
  background: var(--color-red);
  color: var(--color-white);
  font-size: 22px;
  font-weight: 900;
  text-transform: uppercase;
  border-radius: 0 0 10px 10px;
  padding: 8px 12px;
  margin-bottom: 10px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 5px;

  @include media-breakpoint(820px) {
    font-size: 20px;
  }

  @include media-breakpoint('xs') {
    font-size: 18px;
  }
}

.arrow {
  transform: rotate(90deg);
  transition: transform .3s;

  &.showFilter {
    transform: rotate(180deg);
  }
}

.closeIcon {
  width: 25px !important;
}