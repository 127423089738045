.oSelect {
  position: relative;
  margin: 10px 0;
}

.oSelectField {
  display: block;
  width: 100%;
  padding: 5px 10px;
  border: 2px solid var(--color-black);
  margin-bottom: 5px;

  &:focus {
    outline: none;
  }
}

.oSelectField:disabled {
  border-color: #d2d2d2;
  pointer-events: none;
}

.oSelectField:disabled + .oSelectLabel {
  color: #d2d2d2;
}

.oSelectLabel {
  cursor: text;
  user-select: none;
  transition: all .4s;
  padding: 5px;
}
