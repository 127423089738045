@import "../../sass/base/_breakpoints.scss";
@import "../../sass/base/_border.scss";


.post {
  display: grid;
  background: var(--color-red);
  color: var(--color-white);
  text-decoration: none;
  transition: all .3s;
  height: 100%;

  &:hover {
    box-shadow: 0 5px 10px rgba(0,0,0,0.2);
    transform: translateY(-5px);
  }
}

.contentCol {
  height: 100%;
}

.content {
  padding: 14px 10px 14px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
}

.title {
  font-size: 20px;
  font-family: Archivo, sans-serif;
  margin: 10px 0 0;
  word-break: break-word;
  width: 100%;
  hyphens: auto;
}

.excerpt {
  margin: 10px 0;
}

.buttonHolder {
  text-align: right;
}

.button {
  display: inline-block;
  font-family: Archivo, sans-serif;
  background: var(--color-white);
  color: var(--color-red);
  padding: 5px 25px;
  transition: background .3s, color .3s;
  border: 4px solid var(--color-white);
  margin: auto 0 0;
  text-transform: uppercase;

  &:hover {
    background: var(--color-red);
    color: var(--color-white);
  }
}

.imageHolder {
  width: 100%;
  height: 100%;
  position: relative;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.imageBorder {
  border-width: $thick 0 $thick $thick;
  border-style: solid;
  border-color: var(--color-red);
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;

  &.dashed {
    border-style: dashed;
  }

  &.thinBorder {
    border-width: $thin 0 $thin $thin;
  }

  &.normalBorder {
    border-width: $normal 0 $normal $normal;
  }
}

.postLTR {
  flex-direction: row-reverse;

  .imageBorder {
    border-width: 10px 10px 10px 0;

    &.thinBorder {
      border-width: $thin $thin $thin 0;
    }

    &.normalBorder {
      border-width: $normal $normal $normal 0;
    }
  }

  .content {
    padding: 14px 0 14px 14px;
  }
}
