@import "../../sass/base/_breakpoints.scss";

.author {
  display: flex;
  width: 100%;

  @include media-breakpoint('m') {
    flex-wrap: wrap;
  }
}

.image {
  flex-basis: 20%;
  width: 20%;
  display: block;
  margin-right: 20px;

  @include media-breakpoint('m') {
    flex-basis: 70%;
    width: 70%;
    margin: 0 0 20px;
  }
}

.infos {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: var(--color-red);
  flex-basis: 40%;

  @include media-breakpoint('m') {
    flex-basis: 100%;
    width: 100%;
  }
}

.preTitle {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
}

.name {
  color: var(--color-red);
  text-transform: uppercase;
  font-family: Archivo, sans-serif;
  font-size: 20px;
}

.date {
  margin-bottom: 10px;
}

.text {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  margin-bottom: 15px;

  p {
    margin: 0;
  }
}

.mail {
  margin-top: auto;
  display: inline-block;
  background: var(--color-red);
  color: var(--color-white);
  padding: 5px 20px;
  text-transform: uppercase;
  font-family: Archivo, sans-serif;
  text-decoration: none;
  transition: color .3s, background .3s;
  border: 4px solid var(--color-red);

  &:hover {
    color: var(--color-red);
    background: var(--color-white);
  }
}
