.title {
  font-family: Archivo, sans-serif;
  text-align: center;
  font-size: 26px;
  text-transform: uppercase;
  margin-bottom: 50px;
  transition: opacity .5s;
  opacity: 1;
  position: relative;
}

.titleIcon {
  width: 95px;
  position: absolute;
  top: -25px;
  left: calc(50% - 150px);
}

.isLoading {
  opacity: 0.4;
  pointer-events: none;
}

.loadMore {
  font-family: Archivo, sans-serif;
  font-size: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: color .3s;

  &:hover {
    cursor: pointer;
    color: var(--color-red);

    .loadMoreIcon {
      fill: var(--color-red);
    }
  }
}

.loadMoreIcon {
  width: 45px;
  display: block;
  margin-top: 10px;
  transition: fill .3s;
}

.blogCol {
  margin-bottom: 30px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page {
  background: var(--color-white);
  border: 5px solid var(--color-red);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 20px;
  color: var(--color-black);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background .3s;
  margin: 5px;

  &:hover {
    cursor: pointer;
    background: var(--color-red);
    color: var(--color-white);
  }

  &.active {
    pointer-events: none;
    background: var(--color-red);
    color: var(--color-white);
  }
}

.next {
  width: 38px;
  margin-left: 5px;
  padding: 10px;
  transition: transform .3s;
  path {
    fill: var(--color-red);
  }
  &:hover {
    cursor: pointer;
    transform: translateX(5px);
  }
  &.disabled {
    pointer-events: none;
    opacity: .3;
  }
}

.prev {
  width: 38px;
  margin-right: 5px;
  transition: transform .3s;
  padding: 10px;
  path {
    fill: var(--color-red);
  }
  &:hover {
    cursor: pointer;
    transform: translateX(-5px);
  }
  &.disabled {
    pointer-events: none;
    opacity: .3;
  }
}