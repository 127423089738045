.searchValue {
  font-size: 22px;
  margin-bottom: 35px;
}

.result {
  font-family: Archivo, sans-serif;
  font-size: 28px;
  font-weight: bold;
  display: block;
  color: var(--color-red);
  text-decoration: none;
  margin-bottom: 20px;
  cursor: pointer;
  position: relative;
  padding: 20px 10px;
  transition: color .5s;
  overflow: hidden;
  background: var(--color-lightgray);
}

.result:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 250%; height: 100%;
  background: var(--color-red);
  transform-origin:0 0 ;
  transform: translateX(-120%) skewX(45deg);
  transition: transform .5s;
  z-index: -1;
}

.result:hover {
  color: var(--color-white);
  background: none;

  &:before {
    transform: translateX(-20%) skewX(45deg);
  }
}