.rainbow {
  position: relative;
  box-sizing: border-box;
  background: var(--site-color-bg);
  background-clip: padding-box;
  border: solid 3px transparent;
  border-radius: 14px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -3px;
    border-radius: inherit;
    background: var(--color-rainbow);
  }
}

.button {
  font-family: 'Archivo', sans-serif;
  font-weight: 800;
  font-size: 16px;
  letter-spacing: 0.03em;
  cursor: pointer;
  text-decoration: none;
  padding: 10px 13px;
  display: flex;
  align-items: center;

  svg {
    width: 12px;
    margin-left: 10px;
  }

  &:hover {
    text-decoration: underline;
  }
}