@use "sass:math";
@import "../../sass/base/breakpoints";
$site_columns: 12;


.column {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  padding: 0 15px;
}

.row.spacing--small .column {
  padding: 0 7.5px;
}

@for $i from 1 through $site_columns {
  .column--#{$i} {
    flex-basis: 100% * math.div($i, $site_columns);
    max-width: 100% * math.div($i, $site_columns);
    min-width: 100% * math.div($i, $site_columns);
  }
}

@each $key, $value in $global-breakpoints {
  @media only screen and (max-width: #{$value}) {
    @for $i from 1 through $site_columns {
      .column--#{$key}-#{$i} {
        flex-basis: 100% * math.div($i, $site_columns);
        max-width: 100% * math.div($i, $site_columns);
        min-width: 100% * math.div($i, $site_columns);
      }
    }
  }
}


