.titleHolder {
  text-align: center;
}

.isRed {
  color: var(--color-red);
}

.alignleft {
  text-align: left;
}
.alignright {
  text-align: right;
}
.aligncenter {
  text-align: center;
}