@import "../../sass/base/_breakpoints.scss";
@import "../../sass/base/_border.scss";

$site-cols: 4;
$gap: 20px;
$gap_mobile: 10px;


.linkBoxes {
  display: grid;
  grid-auto-flow: dense;
  grid-gap: $gap;
  row-gap:  $gap;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(4, 1fr);
  margin: 50px 0;

  @include media-breakpoint('m') {
    grid-gap: $gap_mobile;
    row-gap:  $gap_mobile;
  }
}

.linkBox {
  position: relative;
  height: 0;
  padding-bottom: calc(100% - 10px);
  grid-column-start: span 1;
  grid-row-start:    span 1;

  @include media-breakpoint('m') {
    grid-column-start: span 2;
    grid-row-start:    span 2;
  }

  &:hover {
    .image {
      transform: scale(1.02);
    }
  }
}

.linkBox.rectangle {
  grid-column-start: span 2;
  grid-row-start:    span 1;
  padding-bottom: calc(50% - 20px);

  @include media-breakpoint('m') {
    grid-column-start: span 4;
    grid-row-start:    span 2;
  }
}

.linkBox.squareBig {
  grid-column-start: span 2;
  grid-row-start:    span 2;
  padding-bottom: calc(100% - 6px);

  @include media-breakpoint('m') {
    grid-column-start: span 4;
    grid-row-start:    span 4;
  }
}

.imageHolder {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.image {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  object-position: center;
  object-fit: cover;
  transition: transform .6s;
  transform: scale(1);
}

.courseTitle,
.title {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  color: var(--color-white);
  font-family: Archivo, sans-serif;
  padding: 10px;
  text-transform: uppercase;
  hyphens: auto;
  line-height: 1;
  font-weight: 900;
  min-height: 60px;

  @include media-breakpoint('m') {
    padding: 5px 0 0 5px;
  }
}

.isVideo {
  padding: 0;
}

.video {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
}

.videoOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .3s;
}

.videoPlay {
  width: 150px;
  pointer-events: none;
  transition: opacity .3s;
}

.isPlaying {
  .videoOverlay {
    opacity: 0;
    pointer-events: none;
  }

  .videoPlay {
    opacity: 0;
    pointer-events: none;
  }
}

.courseTitle {
  position: relative;
  padding: 0 10px 10px 5px;
}

.course .title {
  transition: opacity .5s;
}

.course:hover .title {
  opacity: 0;
}

.title.hasBackground {
  background: var(--color-red)
}

.linkBoxInner {
  overflow: hidden;
}

.icon {
  position: absolute;
  bottom: -2%;
  right: -12%;
  max-width: 50%;
  transform: rotate(15deg);

  @include media-breakpoint('l') {
    right: -8%;
  }
}

.courseInner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(#E4154B, 0.8);
  color: var(--color-white);
  font-family: Archivo, sans-serif;
  font-size: 22px;
  padding: 10px;
  opacity: 0;
  transition: opacity .5s;

  &:hover {
    opacity: 1;
  }
}

.dashed .courseInner {
  left: -$thick;
  right: -$thick;
  top: -$thick;
  bottom: -$thick;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
}

.courseBtn {
  background: var(--color-white);
  padding: 10px;
  text-transform: uppercase;
  color: var(--color-red);
  display: inline-block;
  margin-top: auto;
  font-size: 16px;
  border: 4px solid var(--color-white);
  transition: background .3s, color .3s;

  &:hover {
    background: transparent;
    color: var(--color-white);
  }
}

.linkBox.squareBig {
  .courseInner {
    justify-content: flex-end;
  }

  .courseBtn {
    margin-top: 20px;
  }
}

.infoContent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  margin-bottom: 5px;
  width: 100%;

  @include media-breakpoint('m') {
    font-size: 16px;
  }
}

.infoIcon {
  width: 32px;
  height: 32px;
  margin-right: 10px;
  flex-basis: 40px;
  fill: white;

  @include media-breakpoint('m') {
    width: 27px;
    height: 27px;
  }
}

.infoHeading {
  flex: 1;
}

.courseContent {
  padding: 0;
  text-transform: inherit;
  font-weight: 400;
  width: 100%;
}


