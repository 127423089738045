@import "../../sass/base/_breakpoints.scss";

.noResults {
  font-size: 22px;
  color: var(--color-red);
  text-align: center;
  width: 100%;
  padding: 30px 0;
}

.selectedFilter {
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.selectedFilterOption {
  color: var(--color-white);
  padding: 5px 10px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  transition: filter .3s;

  &.for {
    background: var(--color-blue);
  }
  &.where {
    background: var(--color-red);
  }
  &.when {
    background: var(--color-green);
  }

  &:hover {
    cursor: pointer;
    filter: brightness(0.95);
  }

  &:active {
    transform: translateY(2px);
  }
}

.closeIcon {
  width: 15px;
  fill: var(--color-white);
}

.selectedTitle {
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 18px;
}

.courseList {
  margin: 20px 0;
}

.listItem {
  display: flex;
  padding: 30px 0 0;
  color: inherit;
  text-decoration: none;
  font-size: 18px;

  @include media-breakpoint('m') {
    flex-wrap: wrap;
    border: 4px solid var(--color-red);
    padding: 10px 10px;
    margin-bottom: 30px;
  }

  &:hover {
    > div {
      color: var(--color-red);
    }
  }

  &.is_past {
    color: rgba(0,0,0, 0.5);
  }
}

.soldOutHolder {
  position: relative;

  &:hover {
    &:after {
      pointer-events: auto;
      opacity: 1;
    }
  }

  &:after {
    font-size: 14px;
    display: block;
    content: 'ausgebucht';
    position: absolute;
    left: 0;
    top: 100%;
    width: auto;
    background: var(--color-red);
    color: var(--color-white);
    padding: 8px 10px;
    pointer-events: none;
    opacity: 0;
    transition: opacity .3s;
    transition-delay: .2s;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  }
}

.soldOutIcon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.itemName,
.itemWhen {
  flex: 1;
  flex-basis: 35%;
  width:      35%;
  padding: 5px 20px;
  border-left: 1px solid var(--color-red);
  border-bottom: 3px solid var(--color-red);
  transition: color .3s;

  @include media-breakpoint('m') {
    flex-basis: 100%;
    width: 100%;
    border: 0;
    padding: 10px 10px;
  }
}

.itemName {
  border-left: 0;
  font-weight: bold;
  display: flex;
  flex-basis: 65%;
  width:      65%;

  @include media-breakpoint('m') {
    border-bottom: 2px solid var(--color-red);
  }
}

.itemWhere {
  @include media-breakpoint('m') {
    border-bottom: none;
  }
}