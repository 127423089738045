@import "../../sass/base/_breakpoints.scss";

.image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.slide {
  width: 100%;
}

.slideFigure {

}

.imageHolder {
  position: relative;
  width: 100%;
  height: 0 !important;
  padding-bottom: 50%;
}

.swiper {
  width: 100%;
}

.next,
.prev {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 40px;
  z-index: 10;
  cursor: pointer;
}

.prev {
  left: 15px;
}

.next {
  right: 15px;
}
