@import "../../sass/base/_breakpoints.scss";

.form {
  opacity: 1;
  transition: opacity .3s;
}

.isLoading {
  opacity: 0.6;
}

.isSuccess {
  //.submitHolder,
  //.area {
  //  opacity: 0;
  //  height: 0;
  //}
}

.area {
  position: relative;
  box-sizing: border-box;
  background: var(--site-color-bg);
  background-clip: padding-box;
  border: solid 6px transparent;
  border-radius: 27px;
  padding: 30px;
  margin-bottom: 30px;
  transition: opacity .3s, height .3s;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -6px;
    border-radius: inherit;
    background: var(--color-rainbow);
  }

  p {
    margin: 0;
  }

  a {
    transition: color .3s;

    &:hover {
      color: var(--color-red);
    }
  }
}

.areaTitle {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 30px;
  text-align: center;
}

.submitHolder {
  text-align: center;
  transition: opacity .3s, height .3s;
  display: flex;
  justify-content: flex-end;
}


.hasError {
  > input {
    border-color: var(--color-red);
  }

  + label,
  label {
    color: var(--color-white);
    background: var(--color-red);
  }

  label:before,
  label:after {
    border-color: var(--color-red);
  }
}

.message {
  font-size: 18px;
  text-align: center;
  margin-top: 15px;
  transition: font-size .3s;

  &.success {
    font-size: 24px;
    color: var(--color-black);
  }
}

.icon {
  position: absolute;

  &.topLeft {
    left: -140px;
    top: 0;
  }

  &.topRight {
    right: -140px;
    top: 0;
  }

  &.bottomLeft {
    left: -140px;
    bottom: 0;
  }

  &.bottomRight {
    right: -140px;
    bottom: 0;
  }

  @include media-breakpoint('m') {
    position: relative;
  }
}

.iconImage {
  width: 120px;
}

